<template>
    <div class="page-table mainDiv mb-30" id="affix-container">
        <div class="card-base animated fadeInUp baslikBg">
            <span v-if="valueVisible">{{$t("src.views.apps.firma.guncelleme.baslikDetay")}}</span>
            <span v-else>{{$t("src.views.apps.firma.guncelleme.baslikGuncelle")}}</span>

            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button
                        v-on:click="refreshPage()"
                        style="margin-left: 15px !important"
                        type="text"
                        icon="el-icon-refresh"
                        class="routeButton">
                    </el-button>
                </el-tooltip>
            </div>
        </div>

        <!-- loading true ise loading için gerekli olan mesaj ekrana basılıyor, loading false ise loadingData true değeri almış demektir. O zaman loadingData mesajı basılıyor. -->
        <div class="animated fadeInUp" v-loading="loading || loadingData"
        :element-loading-text='loading ? $t("src.views.apps.firma.detail.updateLoading") : $t("src.views.apps.firma.detail.detailLoading")'
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 1)">
            <el-form status-icon :model="firmaForm" :rules="rulesFirma" ref="firmaForm">
                <el-row :gutter="10">
                    <el-col :lg="12" :md="12" :sm="24" :xs="24">
                        <div class="card-base card-shadow--medium">
                            <div class="flip-card-front" v-if="!editFirma">
                                <el-button v-if="root || yetkili || guncellemeYetkisi" v-on:click="edit('editFirma')" type="warning" size="mini" icon="el-icon-edit"></el-button>
                                <el-divider content-position="left">{{$t("src.views.apps.firma.detail.firmaBilgileri")}}</el-divider>
                                <div class="flex center demo-box" style="min-height: 277px">
                                    <div class="right-box box grow">
                                        <dl class="horizontal">
                                            <dt><i class="mdi mdi-city"></i>{{$t("src.views.apps.firma.tanimlama.firmaKodu")}}
                                            </dt>
                                            <dd>: {{this.firmadetayi.firmaKodu}}</dd>
                                            <el-divider></el-divider>
                                            <dt><i class="mdi mdi-city"></i>{{$t("src.views.apps.firma.tanimlama.firmaAdi")}}
                                            </dt>
                                            <dd>: {{this.firmadetayi.firmaAdi}}</dd>
                                            <el-divider></el-divider>
                                            <dt><i class="mdi mdi-city-variant-outline"></i>{{$t("src.views.apps.firma.tanimlama.vergiDairesi")}}
                                            </dt>
                                            <dd>: {{this.firmadetayi.vergiDairesi}}</dd>
                                            <el-divider></el-divider>
                                            <dt><i class="mdi mdi-numeric"></i>{{$t("src.views.apps.firma.tanimlama.vergiNo")}}
                                            </dt>
                                            <dd>: {{this.firmadetayi.vergiNo}}</dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>

                            <div :span="24" v-if="editFirma">
                                <div class="btnBack">
                                    <el-button v-on:click="firmaGuncelleme('formName', 'editFirma')" type="success" size="mini" icon="el-icon-check"></el-button>
                                    <el-button v-on:click="finishEdit('editFirma')" type="danger" size="mini" icon="el-icon-close"></el-button>
                                </div>

                                <div>
                                    <el-row>
                                        <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                            <el-form-item :label='$t("src.views.apps.firma.tanimlama.firmaAdi")' prop="firmaAdi">:
                                                <el-input v-on:change="$store.commit('changeTabsClose', false)" v-model="firmaForm.firmaAdi"  :placeholder='$t("src.views.apps.firma.tanimlama.firmaPlace")'></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>

                                <div class="col-p" style="margin: 5px 0">
                                    <el-form-item :label='$t("src.views.apps.firma.tanimlama.vergiDairesi")' prop="vergiDairesi">:
                                        <el-input v-on:change="$store.commit('changeTabsClose', false)" v-model="firmaForm.vergiDairesi" :placeholder='$t("src.views.apps.firma.tanimlama.vergiDairesiPlace")'></el-input>
                                    </el-form-item>
                                </div>

                                <div class="col-p">
                                    <el-form-item :label='$t("src.views.apps.firma.tanimlama.vergiNo")' prop="vergiNo">:
                                        <el-input v-on:change="$store.commit('changeTabsClose', false)" v-model="firmaForm.vergiNo" v-mask="'##########################'" :placeholder='$t("src.views.apps.firma.tanimlama.vergiNoPlace")'></el-input>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </el-col>

                    <el-col :lg="12" :md="12" :sm="24" :xs="24">
                        <div class="card-base card-shadow--medium">
                            <el-col :span="24" class="flip-card-front" v-if="!editSite">
                                <el-row>
                                    <el-button v-if="root || yetkili || guncellemeYetkisi" v-on:click="edit('editSite')" type="warning" size="mini" icon="el-icon-edit"></el-button>
                                    <el-divider content-position="left">{{$t("src.views.apps.firma.detail.siteBilgileri")}}</el-divider>
                                    <div class="flex center demo-box" style="min-height: 277px">
                                        <div class="right-box box grow">
                                            <dl class="horizontal">
                                                <dt><i class="mdi mdi-cursor-default"></i>{{$t("src.views.apps.firma.tanimlama.siteAdi")}}
                                                </dt>
                                                <dd>: {{this.firmadetayi.siteAdi}}</dd>
                                                <el-divider></el-divider>
                                                <dt><i class="mdi mdi-new-box"></i>{{$t("src.views.apps.firma.tanimlama.slogan")}}
                                                </dt>
                                                <dd>: {{this.firmadetayi.slogan}}</dd>
                                                <el-divider></el-divider>
                                                <dt><i class="mdi mdi-web"></i>{{$t("src.views.apps.firma.tanimlama.siteAdresi")}}
                                                </dt>
                                                <dd>: {{this.firmadetayi.siteAdresi}}</dd>
                                                <el-divider></el-divider>
                                                <dt><i class="mdi mdi-web"></i>{{$t("src.views.apps.firma.tanimlama.siteAdresi2")}}
                                                </dt>
                                                <dd>: {{this.firmadetayi.siteAdresi2}}</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </el-row>
                            </el-col>

                            <el-col :span="24" v-if="editSite">
                                <div class="btnBack">
                                    <el-button v-on:click="firmaGuncelleme('formName','editSite')" type="success" size="mini" icon="el-icon-check"></el-button>
                                    <el-button v-on:click="finishEdit('editSite')" type="danger" size="mini" icon="el-icon-close"></el-button>
                                </div>

                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.firma.tanimlama.siteAdi")' prop="siteAdi">:
                                            <el-input v-model="firmaForm.siteAdi" v-on:change="$store.commit('changeTabsClose', false)" :placeholder='$t("src.views.apps.firma.tanimlama.firmaPlace")'></el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.firma.tanimlama.slogan")' prop="slogan">:
                                            <el-input v-model="firmaForm.slogan" v-on:change="$store.commit('changeTabsClose', false)" :placeholder='$t("src.views.apps.firma.tanimlama.sloganPlace")'></el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.firma.tanimlama.siteAdresi")' prop="siteAdresi">:
                                            <el-input v-model="firmaForm.siteAdresi" v-on:change="$store.commit('changeTabsClose', false)" :placeholder='$t("src.views.apps.firma.tanimlama.siteAdresiPlace")'>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.firma.tanimlama.siteAdresi2")' prop="siteAdresi2">:
                                            <el-input v-model="firmaForm.siteAdresi2" v-on:change="$store.commit('changeTabsClose', false)" :placeholder='$t("src.views.apps.firma.tanimlama.siteAdresiPlace")'>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item label='Anahtar Kelimeler' prop="keyword">:
                                            <el-row>
                                                <el-col :lg="20" :md="20" :sm="20" :xs="20">
                                                    <el-input v-model="firmaForm.keyword" v-on:change="$store.commit('changeTabsClose', false)" placeholder='Site Aranırken Kullanılacak Olan Anahtar Kelimeler'>
                                                    </el-input>
                                                </el-col>

                                                <el-col :lg="4" :md="4" :sm="4" :xs="4">
                                                    <el-button style="width: 100%" type="primary" @click="addTag">{{$t("src.views.apps.firma.detail.tanimla")}}</el-button>
                                                </el-col>
                                            </el-row>

                                            <el-row class="pt-10 pb-10">
                                                <el-col :lg="24" :md="24" :sm="24" :xs="24">
                                                    <el-tag
                                                    v-for="(tag, index) in firmaForm.keywordList"
                                                    :key="tag.name"
                                                    closable
                                                    @close="removeTag(index)"
                                                    :type="tag.type">
                                                    {{tag}}
                                                    </el-tag> 
                                                </el-col>
                                            </el-row>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item label='Site Tema Rengi' prop="renk">:<br>
                                            <el-color-picker color-format="hex" v-model="firmaForm.renk"></el-color-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </div>
                    </el-col>
                </el-row>
                
                <el-row style="margin-top: 10px">
                    <el-col :span="24">
                        <div>
                            <div class="card-base card-shadow--medium">
                                <el-col :span="24" class="flip-card-front" v-if="!editIletisim">
                                    <el-row>
                                        <el-button v-if="root || yetkili || guncellemeYetkisi" v-on:click="edit('editIletisim')" type="warning" size="mini" icon="el-icon-edit"></el-button>
                                        <el-divider content-position="left">{{$t("src.views.apps.firma.detail.iletisimBilgileri")}}</el-divider>
                                        <div class="flex center demo-box">
                                            <div class="right-box box grow">
                                                <dl class="horizontal">
                                                    <dt><i class="mdi mdi-email"></i>{{$t("src.views.apps.firma.tanimlama.eposta")}}
                                                    </dt>
                                                    <dd>: {{this.firmadetayi.eposta}}</dd>
                                                    <el-divider></el-divider>
                                                    <dt><i class="mdi mdi-email"></i>{{$t("src.views.apps.firma.tanimlama.eposta2")}}
                                                    </dt>
                                                    <dd>: {{this.firmadetayi.eposta2}}</dd>
                                                    <el-divider></el-divider>
                                                    <dt><i class="mdi mdi-phone"></i>{{$t("src.views.apps.firma.tanimlama.telefon")}}
                                                    </dt>
                                                    <dd>: {{this.firmadetayi.sabitTelefon}}</dd>
                                                    <el-divider></el-divider>
                                                    <dt><i class="mdi mdi-phone-classic"></i>{{$t("src.views.apps.firma.tanimlama.telefon2")}}
                                                    </dt>
                                                    <dd>: {{this.firmadetayi.telefon}}</dd>
                                                    <el-divider></el-divider>
                                                    <dt><i class="mdi mdi-phone-classic"></i>{{$t("src.views.apps.firma.tanimlama.dahili")}}
                                                    </dt>
                                                    <dd>: {{this.firmadetayi.dahili}}</dd>
                                                    <el-divider></el-divider>
                                                    <dt><i class="mdi mdi-fax"></i>{{$t("src.views.apps.firma.tanimlama.fax")}}
                                                    </dt>
                                                    <dd>: {{this.firmadetayi.fax}}</dd>
                                                    <el-divider></el-divider>
                                                    <dt><i class="mdi mdi-map-marker"></i>{{$t("src.views.apps.firma.tanimlama.adres")}}
                                                    </dt>
                                                    <dd>: {{this.firmadetayi.adres}}</dd>
                                                    <el-divider></el-divider>
                                                    <dt><i class="mdi mdi-map-marker"></i>{{$t("src.views.apps.firma.tanimlama.koordinat")}}
                                                    </dt>
                                                    <dd>: {{this.firmadetayi.koordinat}}</dd>
                                                    <div v-if="firmadetayi.facebook">
                                                    <el-divider></el-divider>
                                                    <dt><i class="mdi mdi-facebook"></i>facebook
                                                    </dt>
                                                    <el-link target="_blank" :underline="false" :href="this.firmadetayi.facebook">
                                                        <dd>: {{this.firmadetayi.facebook}}</dd>
                                                    </el-link>
                                                </div>

                                                <div v-if="firmadetayi.instagram">
                                                    <el-divider></el-divider>
                                                    <dt><i class="mdi mdi-instagram"></i>Instagram</dt>
                                                    <el-link target="_blank" :underline="false" :href="this.firmadetayi.instagram">
                                                        <dd>: {{this.firmadetayi.instagram}}</dd>
                                                    </el-link>
                                                </div>

                                                <div v-if="firmadetayi.twitter">
                                                    <el-divider></el-divider>
                                                    <dt><i class="mdi mdi-twitter"></i>Twitter
                                                    </dt>
                                                    <el-link target="_blank" :underline="false" :href="this.firmadetayi.twitter">
                                                        <dd>: {{this.firmadetayi.twitter}}</dd>
                                                    </el-link>
                                                </div>

                                                <div v-if="firmadetayi.linkedin">
                                                    <el-divider></el-divider>
                                                    <dt><i class="mdi mdi-linkedin"></i>LinkedIn
                                                    </dt>
                                                    <el-link target="_blank" :underline="false" :href="this.firmadetayi.linkedin">
                                                        <dd>: {{this.firmadetayi.linkedin}}</dd>
                                                    </el-link>
                                                </div>

                                                <div v-if="firmadetayi.google">
                                                    <el-divider></el-divider>
                                                    <dt><i class="mdi mdi-google"></i>Google
                                                    </dt>
                                                    <el-link target="_blank" :underline="false" :href="this.firmadetayi.google">
                                                        <dd>: {{this.firmadetayi.google}}</dd>
                                                    </el-link>
                                                </div>

                                                <div v-if="firmadetayi.youtube">
                                                    <el-divider></el-divider>
                                                    <dt><i class="mdi mdi-youtube"></i>YouTube
                                                    </dt>
                                                    <el-link target="_blank" :underline="false" :href="this.firmadetayi.youtube">
                                                        <dd>: {{this.firmadetayi.youtube}}</dd>
                                                    </el-link>
                                                </div>

                                                <div v-if="firmadetayi.swarm">
                                                    <el-divider></el-divider>
                                                    <dt><i class="mdi mdi-swarm"></i>Swarm
                                                    </dt>
                                                    <el-link target="_blank" :underline="false" :href="this.firmadetayi.swarm">
                                                        <dd>: {{this.firmadetayi.swarm}}</dd>
                                                    </el-link>
                                                </div>

                                                <div v-if="firmadetayi.watsapp">
                                                    <el-divider></el-divider>
                                                    <dt><i class="mdi mdi-whatsapp"></i>WhatsApp
                                                    </dt>
                                                    <el-link target="_blank" :underline="false" :href="this.firmadetayi.watsapp">
                                                        <dd>: {{this.firmadetayi.watsapp}}</dd>
                                                    </el-link>
                                                </div>

                                                <div v-if="firmadetayi.skype">
                                                    <el-divider></el-divider>
                                                    <dt><i class="mdi mdi-skype"></i>Skype
                                                    </dt>
                                                    <el-link target="_blank" :underline="false" :href="this.firmadetayi.skype">
                                                        <dd>: {{this.firmadetayi.skype}}</dd>
                                                    </el-link>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                </el-row>
                            </el-col>

                            <el-col :span="24" v-if="editIletisim">
                                <div class="btnBack">
                                    <el-button v-on:click="firmaGuncelleme('formName','editIletisim')" type="success" size="mini" icon="el-icon-check"></el-button>
                                    <el-button v-on:click="finishEdit('editIletisim')" type="danger" size="mini" icon="el-icon-close"></el-button>
                                </div>

                                <el-row style="margin-top: 10px">
                                    <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.firma.tanimlama.eposta")' prop="eposta">:
                                            <el-input v-on:change="$store.commit('changeTabsClose', false)" v-model="firmaForm.eposta" placeholder='info@erasmus.com.tr'></el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.firma.tanimlama.eposta2")' prop="eposta2">:
                                            <el-input v-on:change="$store.commit('changeTabsClose', false)" v-model="firmaForm.eposta2" placeholder='info@erasmus.com.tr'></el-input>
                                        </el-form-item>
                                    </el-col>
                    
                                    <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.firma.tanimlama.telefon")' prop="telefon">:
                                            <el-input v-on:change="$store.commit('changeTabsClose', false)" v-model="firmaForm.telefon" v-mask="'+90(###)### ## ##'" placeholder="+90(0555)555 55 55"></el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.firma.tanimlama.telefon2")' prop="sabitTelefon">:
                                            <el-input v-on:change="$store.commit('changeTabsClose', false)" v-model="firmaForm.sabitTelefon" v-mask="'+90(###)### ## ##'" placeholder="+90(0555)555 55 55"></el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :lg="4" :md="4" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.firma.tanimlama.dahili")' prop="dahili">:
                                            <el-input v-on:change="$store.commit('changeTabsClose', false)" v-model="firmaForm.dahili" v-mask="'######'" placeholder="555555"></el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :lg="4" :md="4" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.firma.tanimlama.fax")' prop="fax">:
                                            <el-input v-on:change="$store.commit('changeTabsClose', false)" v-model="firmaForm.fax" v-mask="'+90(346)### ## ##'" placeholder="(+90)346-555 55 55"></el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.firma.tanimlama.adres")' prop="adres">:
                                            <el-input type="textarea" v-on:change="$store.commit('changeTabsClose', false)" v-model="firmaForm.adres"></el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.firma.tanimlama.koordinat")' prop="koordinat">:
                                            <el-input v-on:change="$store.commit('changeTabsClose', false)" type="textArea" :rows="3" v-model="firmaForm.koordinat"></el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.firma.tanimlama.sosyal")' prop="valueAna">
                                            <el-select
                                            v-on:change="$store.commit('changeTabsClose', false)"
                                            v-model="firmaForm.valueAna"
                                            style="width: 100%"
                                            multiple
                                            filterable
                                            allow-create
                                            default-first-option
                                            placeholder="Facebook, Instagram, Twitter...">
                                                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-row v-for="(detay,index) in firmaForm.valueAna" v-bind:key="index">
                                    <el-col :span="24" class="col-p">
                                        <el-form-item :label='detay.label'>:
                                            <el-row :gutter="20">
                                                <el-col :span="12">
                                                    <el-input v-model="firmaForm[detay.value]" :prop='firmaForm[detay.value]' v-on:change="sosyalKontrol(detay.value)"></el-input>
                                                </el-col>
                                            </el-row>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-form>
        </div>
    </div>
</template>
<script>
    import firmaService from '../../../WSProvider/FirmaService'
    import notification from '../../../notification'
    import functions from '../../../functions'
    import jwt_decode from "jwt-decode"
    import JQuery from 'jquery';
    import EventBus from '@/components/event-bus'

    let $ = JQuery;

    let userDataDemirag = "";
    export default {
        name: "FirmaDetay",
        mounted() {
            if (localStorage.getItem("userDataDemirag")) {
                userDataDemirag = jwt_decode(localStorage.getItem("userDataDemirag"))
                if(userDataDemirag["yetkiTuru"]){
                    if(userDataDemirag["yetkiTuru"] === "1" || userDataDemirag["yetkiTuru"] === "2"){
                        this.root = true;
                        this.yetkili = true;
                        guncellemeYetkisi: true;
                    }
                }
            }
            this.getFirmaDetay()
        },
        data() {
            return {
                loadingData:false,
                loading: false,

                editFirma : false,
                editSite: false,
                editIletisim: false,
                root : false,
                yetkili : false,
                guncellemeYetkisi: false,
                options: [{value: 'facebook', label: 'Facebook'},
                    {value: 'twitter', label: 'Twitter'},
                    {value: 'google', label: 'Google'},
                    {value: 'instagram', label: 'Instagram'},
                    {value: 'linkedin', label: 'LinkedIn'},
                    {value: 'youtube', label: 'YouTube'},
                    {value: 'swarm', label: 'Swarm'},
                    {value: 'whatsapp', label: 'WhatsApp'},
                    {value: 'skype', label: 'Skype'}],
                valueVisible: true,
                sosyalMedya: [],
                yetkiliList: [],
                firmaDetayListKlon: [],
                updateData: [],
                firmaId: '',
                yetkiliAdSoyad: '',
                firmadetayi: {},
                firmaForm: {
                    firmaKodu: '',
                    renk: '#2c3437',
                    firmaAdi: '',
                    vergiDairesi: '',
                    vergiNo: '',
                    siteAdi: '',
                    valueAna: [],
                    yetkiliId: "",
                    adSoyad: "",
                    slogan: "",
                    keyword: "",
                    keywordList: [],
                    siteAdresi: '',
                    siteAdresi2: '',
                    eposta: '',
                    eposta2: '',
                    telefon: '',
                    sabitTelefon: '',
                    dahili: '',
                    fax: '',
                    adres: '',
                    adres2: '',
                    koordinat: '',
                    facebook: '',
                    twitter: '',
                    google: '',
                    instagram: '',
                    linkedin: '',
                    youtube: '',
                    swarm: "",
                    whatsapp: '',
                    skype: '',
                },
                rulesFirma: {
                    eposta: [
                        {type: 'email', message: 'Please Enter a Valid Email', trigger: ['blur', 'change']}
                    ],
                    eposta2: [
                        {type: 'email', message: 'Please Enter a Valid Email', trigger: ['blur', 'change']}
                    ],
                },
            }
        },
        methods: {
            edit(variable){
                if(variable === "editFirma"){
                    this.editFirma = true
                }
                if(variable === "editSite"){
                    this.editSite = true
                }
                if(variable === "editIletisim"){
                    this.editIletisim = true
                }
            },
            finishEdit(variable){
                if(variable === "editFirma"){
                    this.editFirma = false
                }
                if(variable === "editSite"){
                    this.editSite = false
                }
                if(variable === "editIletisim"){
                    this.editIletisim = false
                }
            },
            sosyalKontrol(sosyal) {
                this.sosyalMedya.push({
                    title: sosyal,
                    value: this.firmaForm[sosyal]
                })
            },
            removeRowFirma(item, value) {
                this.firmaForm.valueAna.splice(item, 1);
                for (var i = 0; i < this.sosyalMedya.length; i++) {
                    if (value === this.sosyalMedya[i].title) {
                        this.sosyalMedya.splice(i, 1);
                    }
                }
            },

            addTag(){
                if(this.firmaForm.keyword !== ""){
                    this.firmaForm.keywordList.push(this.firmaForm.keyword);
                    this.firmaForm.keyword = "";
                }
            },

            removeTag(index){
                this.firmaForm.keywordList.splice(index, 1)
            },

            getFirmaDetay(){
                this.loadingData = true;
                var self = this;
                firmaService.firmaDetay().then(response => {
                   if(response.status === 200 && Object.keys(response.data).length > 0){
                       this.firmadetayi = response.data;
                       this.firmadetayi.koordinat= functions.htmlDecode(functions.strip_html_tags(response.data.erasmuscode));

                        this.firmaId = response.data.firmaID;
                        this.firmaForm.firmaAdi = response.data.firmaAdi;
                        this.firmaForm.vergiDairesi = response.data.vergiDairesi;
                        this.firmaForm.vergiNo = response.data.vergiNo;
                        this.firmaForm.siteAdi = response.data.siteAdi;
                        this.firmaForm.slogan = response.data.slogan;
                        this.firmaForm.renk = response.data.renk;
                        this.firmaForm.siteAdresi = response.data.siteAdresi;
                        this.firmaForm.siteAdresi2 = response.data.siteAdresi2;
                        this.firmaForm.eposta = response.data.eposta;
                        this.firmaForm.eposta2 = response.data.eposta2;
                        this.firmaForm.telefon = response.data.telefon;
                        this.firmaForm.sabitTelefon = response.data.sabitTelefon;
                        this.firmaForm.dahili = response.data.dahili;
                        this.firmaForm.fax = response.data.fax;
                        this.firmaForm.adres = response.data.adres;
                        // this.firmaForm.koordinat = response.data.koordinat;
                        this.firmaForm.koordinat = functions.htmlDecode(functions.strip_html_tags(response.data.erasmuscode));
                        this.options.forEach(function (e) {
                            if (response.data[e.value] !== null) {
                                var items = {
                                    label: e.label,
                                    value: e.value
                                };
                                self.firmaForm.valueAna.push(items);
                                self.firmaForm[e.value] = response.data[e.value];
                            }
                        })
                        this.firmaDetayListKlon = response.data;
                   }
                   else if(response.status == 200 && Object.keys(response.data).length > 0){
                        notification.Status('warning', this, 'Firma detayı bulunamadı..');
                        history.back();
                   }
                   this.loadingData = false;
               }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }
                        else notification.Status("errorMsg", this, error.errMsg);
                    }else{
                        //notification.Status(503, this)
                    }
                    this.loadingData = false;
                })
            },

            refreshPage(){
                this.getFirmaDetay()
            },
            

            // firmaID, firmaAdi, siteAdi, vergiDairesi, vergiNo, slogan, keyword, siteAdresi, siteAdresi2, renk, eposta, eposta2, telefon, sabitTelefon, dahili, fax, adres, koordinat, map, facebook, twitter, google, instagram, linkedin, youtube, swarm, whatsapp, skype, durum
            firmaGuncelleme(formName, variable) {
                this.loading = true;
                let keywords = this.firmaForm.keywordList.join(",")
                firmaService.firmaGuncelleme(1, this.firmaForm.firmaAdi, this.firmaForm.siteAdi, this.firmaForm.vergiDairesi, this.firmaForm.vergiNo, this.firmaForm.slogan ,keywords, this.firmaForm.siteAdresi, this.firmaForm.siteAdresi2, this.firmaForm.renk, this.firmaForm.eposta, this.firmaForm.eposta2, this.firmaForm.telefon, this.firmaForm.sabitTelefon, this.firmaForm.dahili, this.firmaForm.fax, this.firmaForm.adres, this.firmaForm.koordinat, "harita", this.firmaForm.facebook, this.firmaForm.twitter, this.firmaForm.google, this.firmaForm.instagram, this.firmaForm.linkedin, this.firmaForm.youtube, this.firmaForm.swarm ? this.firmaForm.swarm : "", this.firmaForm.whatsapp, this.firmaForm.skype, 1).then((response) => {
                    
                    if (response.status == 200) {
                        notification.Status('success', this, response.msg);
                        this.finishEdit(variable)
                        this.getFirmaDetay()
                    }
                    this.loading = false;
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }
                        else notification.Status("errorMsg", this, error.errMsg);
                    }else{
                        //notification.Status(503, this)
                    }
                    this.loading = false;
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../assets/scss/_variables';

    .el-row:first-child {
        margin-top: unset !important;
    }

    .page-table {
        &.overflow {
            overflow: hidden;
        }

        .table-box {
            overflow: hidden;
        }
    }

    .el-form-item {
        font-weight: bold;
        margin-bottom: 15px !important;
    }

    /*Flip*/
    #flipFirma, #flipSite {
        width: 100%;
        height: auto;
        perspective: 1000px;
    }

    #flipIletisim {
        width: 100%;
        height: auto;
        perspective: 2500px;
    }

    .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        /*text-align: center;*/
        transition: transform 0.8s;
        transform-style: preserve-3d;
    }

    .flip-card.flipac .flip-card-inner {
        transform: rotateY(180deg);
    }

    .flip-card-front {
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
    }

    .flip-card-back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
    }

    .flip-card-front .el-button {
        display: block;
        margin-left: auto;
        margin-top: 20px;
        margin-right: 20px;
    }

    .btnBack {
        display: flex;
        justify-content: flex-end;
        position: relative;
        top: 10px;
        right: 10px;
        z-index: 100;
    }

    dl i {
        color: #0075c3;
        margin: 0 10px;
    }

    .el-divider--horizontal {
        margin: 22px 0;
    }

    dl.horizontal dt {
        text-align: left;
        width: 25% !important;
    }

    dl.horizontal dd {
        margin-left: unset;
    }

    /*Flip*/
</style>